import { combineEpics, Epic } from 'redux-observable';

import playerEventsEpic, { PlayerEventsEpicDependencies } from './epics/playerEventsEpic';
import mediaEpic, { MediaEpicDependencies } from './epics/mediaEpic';
import metadataEpic, { MetadataEpicDependencies } from './epics/metadataEpic';
import authEpic, { AuthEpicDependencies } from './epics/authEpic';
import { ASVPActions } from './actions';
import { State } from './rootReducer';

export type EpicDependencies = PlayerEventsEpicDependencies &
    MetadataEpicDependencies &
    MediaEpicDependencies &
    AuthEpicDependencies;

export type RootEpic = Epic<ASVPActions, ASVPActions, State, EpicDependencies>;

const rootEpic = combineEpics<RootEpic>(
    playerEventsEpic,
    mediaEpic,
    metadataEpic,
    authEpic
);

export default rootEpic;
