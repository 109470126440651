import { Reducer, combineReducers, Action } from 'redux';
import get from 'lodash/get';
import find from 'lodash/find';
import { SET_OPTIONS, OptionsAction } from './optionsReducer';
import { VideoDataRoot, StreamData } from '../../api/VideoService';


const DEFAULT_POSTER = 'https://i.cdn.turner.com/adultswim/big/image-upload/thumbnails/thumb-2_image-155673728145820.jpg';

//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export type VideoID = string;
export type IsAuth = boolean;
export type MediaID = string;
export type Poster = string;
export type Title = string;
export type TitleID = string;
export type CollectionTitle = string;
export type LaunchDate = number | null;
export type PlaybackURL = string;

export interface MetadataState {
    videoID: VideoID;
    isAuth: IsAuth;
    mediaID: MediaID;
    poster: Poster;
    title: Title;
    titleID: TitleID;
    collectionTitle: CollectionTitle;
    error: string | null;
    playbackURL: PlaybackURL;
    seasonNumber: number | null;
    episodeNumber: number | null;
    duration: number | null;
    videoType: string;
    launchDate: LaunchDate;
}

//
// ─── ACTION TYPES ───────────────────────────────────────────────────────────────
//

export const SET_VIDEO_DATA = 'METADATA__SET_VIDEO_DATA';
export const SET_VIDEO_ID = 'METADATA__SET_VIDEO_ID';
export const SET_ERROR = 'METADATA__SET_ERROR';

export interface SetVideoDataAction extends Action<typeof SET_VIDEO_DATA> {
    payload: VideoDataRoot;
}

export interface SetVideoIDAction extends Action<typeof SET_VIDEO_ID> {
    payload: VideoID;
}

export interface SetErrorAction extends Action<typeof SET_ERROR> {
    payload: string;
}


export type MetadataAction = SetVideoDataAction | SetVideoIDAction | SetErrorAction;

//
// ─── ACTION CREATORS ────────────────────────────────────────────────────────────
//


export const setVideoData = (videoData: VideoDataRoot): SetVideoDataAction => ({
    type: SET_VIDEO_DATA,
    payload: videoData,
})

export const setVideoID = (videoID: VideoID): SetVideoIDAction => ({
    type: SET_VIDEO_ID,
    payload: videoID,
})

export const setError = (error: string): SetErrorAction => ({
    type: SET_ERROR,
    payload: error,
})


//
// ─── REDUCERS ───────────────────────────────────────────────────────────────────
//

export const videoIDReducer: Reducer<VideoID, OptionsAction | MetadataAction> = (state = '', action) => {
    switch (action.type) {
        case SET_OPTIONS:
            return get(action.payload, 'videoID', '');
        case SET_VIDEO_ID:
            return action.payload;
        default:
            return state;
    }
};


export const isAuthReducer: Reducer<IsAuth, MetadataAction> = (state = false, action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.auth', false);
        default:
            return state;
    }
};


export const mediaIDReducer: Reducer<MediaID, MetadataAction> = (state = '', action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.media_id', '');
        default:
            return state;
    }
};


export const posterReducer: Reducer<Poster, MetadataAction> = (state = '', action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.poster', DEFAULT_POSTER);
        default:
            return state;
    }
};


export const titleReducer: Reducer<Title, MetadataAction> = (state = '', action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.title', '');
        default:
            return state;
    }
};


export const collectionTitleReducer: Reducer<CollectionTitle, MetadataAction> = (state = '', action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.collection_title', '');
        default:
            return state;
    }
};


export const titleIDReducer: Reducer<TitleID, MetadataAction> = (state = '', action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.title_id', '');
        default:
            return state;
    }
};


export const errorReducer: Reducer<string | null, MetadataAction> = (state = null, action) => {
    switch (action.type) {
        case SET_ERROR:
            return action.payload;
        default:
            return state;
    }
};

export const playbackURLReducer: Reducer<PlaybackURL, MetadataAction> = (state = '', action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            const mediaID: MediaID = get(action.payload, 'video.media_id', '');

            // we don't want to set a playback url if media id is present
            if (mediaID) return '';

            const stream: StreamData = get(action.payload, 'video.stream', {});
            const assets = get(stream, 'assets', []);
            const playbackAsset = find(assets, (asset) => {
                return (asset.mime_type === 'application/x-mpegURL');
            })
            return (playbackAsset) ? playbackAsset.url.replace('http://', 'https://') : '';
        default:
            return state;
    }
};

export const seasonNumberReducer: Reducer<number | null, MetadataAction> = (state = null, action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            const seasonNumber = get(action.payload, 'video.season_number', null);
            return (!!seasonNumber) ? parseInt(seasonNumber) : null;
        default:
            return state;
    }
};

export const episodeNumberReducer: Reducer<number | null, MetadataAction> = (state = null, action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            const episodeNumber = get(action.payload, 'video.episode_number', null);
            return (!!episodeNumber) ? parseInt(episodeNumber) : null;
        default:
            return state;
    }
};

export const durationReducer: Reducer<number | null, MetadataAction> = (state = null, action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.duration', null);
        default:
            return state;
    }
};

export const videoTypeReducer: Reducer<string, MetadataAction> = (state = '', action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.type', null);
        default:
            return state;
    }
};

export const launchDateReducer: Reducer<LaunchDate | null, MetadataAction> = (state = null, action) => {
    switch (action.type) {
        case SET_VIDEO_DATA:
            return get(action.payload, 'video.launch_date', null);
        default:
            return state;
    }
};

export default combineReducers<MetadataState>({
    videoID: videoIDReducer,
    mediaID: mediaIDReducer,
    poster: posterReducer,
    isAuth: isAuthReducer,
    collectionTitle: collectionTitleReducer,
    title: titleReducer,
    titleID: titleIDReducer,
    error: errorReducer,
    playbackURL: playbackURLReducer,
    seasonNumber: seasonNumberReducer,
    episodeNumber: episodeNumberReducer,
    duration: durationReducer,
    videoType: videoTypeReducer,
    launchDate: launchDateReducer
});
