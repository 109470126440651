import { Store } from 'redux';
import { Observable } from 'rxjs';

/**
 * Creates an observable from a Redux store
 * @param {Store} store Redux store
 */
export const ofStore = <S>(store: Store<S>): Observable<S> =>
    new Observable<S>(subscriber => {
        subscriber.next(store.getState());
        return store.subscribe(() => subscriber.next(store.getState()));
    });
