import { Persistor } from 'redux-persist';
import { Unsubscribable, Observable } from 'rxjs';
import format from 'date-fns/format';

import { configureStore, Store } from './store';
import { destroy, ready } from './store/actions';
import { State } from './store/rootReducer';
import { ofStore } from './utils/observable';
import { TopPlayer } from './player';
import { TopPlayerOptions } from './player/TopPlayer';
import { setOptions } from './store/reducers/optionsReducer';
import { authError, AuthContext, AuthError } from './store/reducers/authReducer';
import { setVideoID, VideoID } from './store/reducers/metadataReducer';
import { authReady } from './store/reducers/authReducer';
import { Player } from '@top/player-block-web';
import {
    AnalyticsEvents,
    ADBPVideoType,
    getCombinedVideoTitle,
    getVideoType,
    inIframe,
} from './tracking';

//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export interface ASVPOptions {
    top: TopPlayerOptions;
    videoID?: string;
    useAuthLib?: boolean;
}

export type TeardownFunction = () => void;

declare global {
    interface Window {
        trackMetrics: any;
        sendVideoEvent: any;
        AS: any;
    }
}

//
// ─── CLASS ──────────────────────────────────────────────────────────────────────
//

export default class ASVP {
    /**
     * Creates an instance of Chat
     *
     * @param {ASVPOptions} [options={}]
     */
    public constructor(public readonly options: ASVPOptions) {
        const playerOptions = options.top;
        let player: Player, auth;

        // initialize the auth library
        if (options.useAuthLib !== false && AS && AS.TOPAuth) {
            auth = AS.TOPAuth;

            if (process.env.NODE_ENV === 'development') auth.toggleAuthFlowControls(true);

            auth.initializeAuth()
                .then((authContext: AuthContext) => {
                    this.store?.dispatch(authReady(authContext));
                })
                .catch((err: AuthError) => {
                    this.store?.dispatch(authError(err));
                });
        }

        // initialize the player (TOP)
        if (playerOptions.container instanceof HTMLElement) {
            player = this.player = TopPlayer(playerOptions);
        } else {
            return;
        }

        // exit here if no player is set
        if (!player) return;

        // initialize the app store
        const { persistor, store } = configureStore({ player, auth });

        this.persistor = persistor;
        this.store = store;

        // apply ready and options after store has been rehydrated
        this.waitFor().then(() => {
            this.store?.dispatch(ready());
            this.store?.dispatch(setOptions(options));
        });

        // Analytics
        // https://wbddigital.atlassian.net/wiki/spaces/INSTR/pages/437683834/AdultSwim+Analytics+Implementation+Guide
        this.lastMediaTime = 0;
        const trackAnalyticsEvent = (eventType: AnalyticsEvents) => {
            try {
                const state = store.getState();

                function getDataFromState() {
                    const adParams = {
                        adDuration: player.getCurrentAdBreak()?.duration,
                        adType: player.getCurrentAdBreak()?.position,
                    };
                    const pauseParams = {
                        paused: player.mediaState === 'paused',
                    };
                    const startParams = {
                        autoplayed: player.config.playback.autoPlay
                    }
                    const lastAirDate = state.metadata.launchDate
                        ? format(new Date(state.metadata.launchDate * 1000), 'YYYY-MM-DD HH:mm:ss')
                        : null;
                    const duration =
                        eventType === AnalyticsEvents.VideoProgress
                            ? player.mediaTime
                            : state.metadata.duration;
                    const videoType = getVideoType(state.metadata.videoType);

                    return {
                        ...(eventType === AnalyticsEvents.VideoPreroll && adParams),
                        ...(eventType === AnalyticsEvents.VideoPause && pauseParams),
                        contentId: state.metadata.mediaID,
                        franchise: state.metadata.collectionTitle,
                        headline: state.metadata.title,
                        id: state.metadata.mediaID,
                        lastAirDate,
                        media: {
                            duration,
                        },
                        mediaId: state.metadata.mediaID,
                        title: state.metadata.title,
                        tveMode: videoType === ADBPVideoType.STREAM ? 'linear' : 'C4',
                        tve_mvpd: state.auth.providerID,
                        trt: duration,
                        type: videoType,
                        video: {
                            id: state.metadata.videoID,
                            title: getCombinedVideoTitle(
                                videoType,
                                state.metadata.isAuth,
                                state.metadata.title,
                                state.metadata.collectionTitle
                            ),
                            season_number: state.metadata.seasonNumber,
                            episode_number: state.metadata.episodeNumber,
                            type: videoType,
                            offsite: inIframe(true),
                            segment_id: 1,
                            duration,
                            ...(eventType === AnalyticsEvents.VideoPause && pauseParams),
                            ...(eventType === AnalyticsEvents.VideoStart && startParams),
                        },
                    };
                }

                if (state.metadata.isAuth) {
                    window.sendVideoEvent({
                        type: eventType,
                        data: getDataFromState(),
                    });
                } else {
                    window.trackMetrics({
                        type: eventType,
                        data: getDataFromState(),
                    });
                }
            } catch (e) {
                console.warn(e);
            }
        };
        player.events.adStarted.listen(() => {
            trackAnalyticsEvent(AnalyticsEvents.VideoPreroll);
        });
        player.events.mediaStarted.listen(() => {
            trackAnalyticsEvent(AnalyticsEvents.VideoStart);
        });
        player.events.mediaTimeChanged.listen(() => {
            // we want to track Analytics every 60 seconds, and sometimes mediaTimeChanged gets called more than once a second
            if (player.mediaTime) {
                const mediaTimeInt = parseInt(player.mediaTime.toString());
                if (mediaTimeInt % 60 === 0 && mediaTimeInt != this.lastMediaTime) {
                    if(mediaTimeInt > 1) {
                        trackAnalyticsEvent(AnalyticsEvents.VideoProgress);
                    }
                    this.lastMediaTime = mediaTimeInt;
                }
            }
        });
        player.events.mediaFinished.listen(() => {
            trackAnalyticsEvent(AnalyticsEvents.VideoComplete);
        });
        player.events.mediaPaused.listen(() => {
            trackAnalyticsEvent(AnalyticsEvents.VideoPause);
        });
        player.events.mediaResumed.listen(() => {
            trackAnalyticsEvent(AnalyticsEvents.VideoPause);
        });
    }

    /**
     * Player
     */
    public readonly player: Player | null = null;

    /**
     * The instance Redux store persistor
     */
    public readonly persistor: Persistor | null = null;

    /**
     * The instance Redux store
     */
    public readonly store: Store | null = null;

    /**
     * A set of teardown functions to call when the instance is destroyed
     */
    private readonly __teardownQueue = new Set<TeardownFunction>();

    /**
     * Destroys the instance and cleans up all listeners
     */
    public destroy(): void {
        this.player?.destroy();

        this.store?.dispatch(destroy());

        for (const fn of this.__teardownQueue) {
            fn();
        }
        this.__teardownQueue.clear();
    }

    /**
     * Add a callback to the instance's destroy event
     *
     * @param {TeardownFunction} fn The callback function
     * @returns {(runFn?: boolean) => void}
     *  A function to remove it from the queue.
     *  Pass `true` to also run the callback
     */
    public onDestroy(fn: TeardownFunction): (runFn?: boolean) => void {
        this.__teardownQueue.add(fn);

        return (runFn?: boolean) => {
            this.__teardownQueue.delete(fn);
            if (runFn) {
                fn();
            }
        };
    }

    /**
     * Wait till the instance has fully initialized
     *
     * @returns {Promise<void>} A Promise when the instance has been setup
     */
    public waitFor(): Promise<void> {
        const { persistor } = this;
        if (!persistor) return Promise.reject();

        return new Promise((resolve) => {
            const unsubscribe = persistor.subscribe(handleState);
            const cancel = this.onDestroy(unsubscribe);

            function handleState() {
                if (persistor && persistor.getState().bootstrapped) {
                    cancel(true);
                    resolve();
                }
            }

            handleState();
        });
    }

    /**
     * 	Set a new video. Will clear out current video and request new one
     *
     * @param {string} videoID unicron id for a video object
     * @returns {this}
     */
    public setVideo(videoID: VideoID) {
        if (!arguments.length) {
            console.error(
                'No arguments passed to setVideo.  It needs a unicron video ID to retrieve video assets.'
            );
            return false;
        }

        this.store?.dispatch(setVideoID(videoID));

        return this;
    }

    /**
     * Returns an observable of the store
     *
     * @returns {Observable<State>}
     */
    public toObservable(): Observable<State> {
        if (!this.store) {
            console.error('Store not set up.');
            return new Observable();
        }

        return ofStore(this.store);
    }

    /**
     * Subscribes to the store. Will be automatically unsubscribed when the instance is destroyed
     *
     * @param {(value: State) => void} observer Observer function that receives the next state value
     * @returns {Unsubscribable}
     */
    public subscribe(observer: (value: State) => void): Unsubscribable {
        const store$ = this.toObservable();
        const subscription = store$.subscribe(observer);
        const cancel = this.onDestroy(() => subscription.unsubscribe());

        return {
            unsubscribe() {
                cancel(true);
            },
        };
    }

    /**
     * Access the TopPlayer directly
     *
     * @returns {player}
     */
    public getPlayer(): any | void {
        if (this.player) return this.player;
        else console.warn('Player tech has not been created yet');
    }

    /**
     * Registers player event listening
     * List of eventNames - https://httpstream.ngtv.io/external/top-2.0/releases/2.0.1/api/develop/player/web/enums/api_events.playereventtype.html
     *
     * @param {string} eventName
     * @param {Function} callback
     * @returns {deregister func}
     */
    on(eventName: string, callback: Function): Function | void {
        if (arguments.length !== 2) {
            console.error(
                'Missing one or more arguments in registerEvent call.  Needs both eventName and callback function in that order.'
            );
            return;
        }

        return this.player?.events[eventName].listen(callback);
    }

    /**
     * Deregisters player event listening
     * @param {any} func
     */
    off(func: any): void {
        if (!arguments.length) {
            console.error(
                'Missing a required argument in deregisterEvent call.  Needs eventName passed in.'
            );
        }

        func.detach();
    }

    // storing last video-progress analytics call time to prevent duplicates
    public lastMediaTime: number = 0;
}
