import { MAX_MESSAGE_BODY_LENGTH } from '../constants';

export function reverseString(value: string) {
    return value
        .split('')
        .reverse()
        .join('');
}

export function cleanInputValue(value: string): string {
    return value
        .replace(/^\s+/g, '') // trim whitespace from beginning
        .replace(/\s{2,}/g, ' ') // replace 2+ spaces with a single space
        .substring(0, MAX_MESSAGE_BODY_LENGTH);
}

export function wrap(str: string, wrapper: string): string {
    return wrapper + str + wrapper;
}

export function wrapEmoji(name: string): string {
    return wrap(name, '::');
}

export function serialize(obj: any, prefix?: string): string {
    var str = [];
    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p];
            str.push(typeof v === 'object' ?
                serialize(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v));
        }
    }
    return str.join('&');
}