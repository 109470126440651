import { Reducer, combineReducers, Action } from 'redux';
import get from 'lodash/get';

//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//
export interface AuthContext {
    adobeHashId: string;
    authenticatedProvider: AuthenticatedProvider;
    authenticatedState: string;
}

export interface AuthenticatedProvider {
    displayName: string;
    country: string;
    id: ProviderID;
}

export interface AuthError {
    message: string;
    code: string;
    severity: string;
    metadata: AuthErrorMetadata;
}

export interface AuthErrorMetadata {
    recoverySuggestion: string;
    subErrorCode: string;
    subErrorMessage: string;
}

export type AuthToken = string;
export type IsAuthenticated = boolean;
export type ProviderID = string;

export interface AuthState {
    error: AuthError | null;
    token: AuthToken;
    isAuthenticated: IsAuthenticated;
    providerID: ProviderID;
}

//
// ─── ACTION TYPES ───────────────────────────────────────────────────────────────
//

export const AUTH_ERROR = 'AUTH__ERROR';
export const AUTH_LOGIN = 'AUTH__LOGIN';
export const AUTH_LOGOUT = 'AUTH__LOGOUT';
export const AUTH_READY = 'AUTH__READY';
export const SET_TOKEN = 'AUTH__SET_TOKEN';

export interface AuthErrorAction extends Action<typeof AUTH_ERROR> {
    payload: AuthError;
}
export interface AuthReadyAction extends Action<typeof AUTH_READY> {
    payload: AuthContext;
}

export type AuthLogoutAction = Action<typeof AUTH_LOGOUT>;

export interface AuthLoginAction extends Action<typeof AUTH_LOGIN> {
    payload: AuthContext;
}

export interface SetTokenAction extends Action<typeof SET_TOKEN> {
    payload: AuthToken;
}


export type AuthAction = AuthErrorAction | SetTokenAction | AuthReadyAction | AuthLoginAction | AuthLogoutAction;

//
// ─── ACTION CREATORS ────────────────────────────────────────────────────────────
//

export const authError = (authError: AuthErrorAction['payload']): AuthErrorAction => ({
    type: AUTH_ERROR,
    payload: authError
})

export const authReady = (authContext: AuthReadyAction['payload']): AuthReadyAction => ({
    type: AUTH_READY,
    payload: authContext
})

export const authLogin = (authContext: AuthLoginAction['payload']): AuthLoginAction => ({
    type: AUTH_LOGIN,
    payload: authContext
})

export const authLogout = (): AuthLogoutAction => ({
    type: AUTH_LOGOUT
})

export const setToken = (token: SetTokenAction['payload']): SetTokenAction => ({
    type: SET_TOKEN,
    payload: token,
})


//
// ─── REDUCERS ───────────────────────────────────────────────────────────────────
//


export const authErrorReducer: Reducer<AuthError | null, AuthAction> = (state = null, action) => {
    switch (action.type) {
        case AUTH_ERROR:
            return get(action.payload, 'severity') === 'error' ? action.payload : null;
        case AUTH_LOGIN:
            return (get(action.payload, 'authenticatedState') === 'authenticated') ? null : state
        case AUTH_LOGOUT:
            return null;
        default:
            return state;
    }
};

export const authTokenReducer: Reducer<AuthToken, AuthAction> = (state = '', action) => {
    switch (action.type) {
        case SET_TOKEN:
            return action.payload;
        default:
            return state;
    }
};

export const isAuthenticatedReducer: Reducer<IsAuthenticated, AuthAction> = (state = false, action) => {
    switch (action.type) {
        case AUTH_READY:
        case AUTH_LOGIN:
            return (get(action.payload, 'authenticatedState') === 'authenticated') ? true : false
        case AUTH_LOGOUT:
            return false;
        default:
            return state;
    }
};

export const setProviderIDReducer: Reducer<ProviderID, AuthAction> = (state = '', action) => {
    switch (action.type) {
        case AUTH_READY:
        case AUTH_LOGIN:
            return get(action.payload, 'authenticatedProvider.id', '');;
        case AUTH_LOGOUT:
            return '';
        default:
            return state;
    }
}



export default combineReducers<AuthState>({
    error: authErrorReducer,
    token: authTokenReducer,
    isAuthenticated: isAuthenticatedReducer,
    providerID: setProviderIDReducer
    // tokenType
});
