import { Observable } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { switchMap } from 'rxjs/operators';
import 'whatwg-fetch';

import { ApiResponse } from '.';

/**
 * Helper class for making observable http requests with fetch
 *
 * @export
 * @class Fetcher
 */
export default class Fetcher<T = ApiResponse> {
    public constructor(public readonly baseURL: string) {}

    /**
     * Uses the baseURL to make a valid endpoint to fetch
     *
     * @private
     * @param {string} path The path to append to the base URL
     * @returns {string}
     * @memberof Fetcher
     */
    private endpoint(path: string): string {
        return [this.baseURL, path].join('');
    }

    public request<R extends T = T>(path: string, config?: RequestInit): Observable<R> {
        const url = this.endpoint(path);

        return fromFetch(url, config).pipe(
            switchMap(async resp => {
                if (!resp.ok) {
                    const error = await this.handleError<R>(resp);
                    return error;
                }
                const json: R = await resp.json();
                return json;
            })
        );
    }

    /**
     * Determine how to handle responses that arent OK.
     * By default, throw with the `errorMessage` field of
     * the response json
     *
     * @private
     * @template R
     * @param {Response} resp
     * @returns {Promise<R>}
     * @memberof Fetcher
     */
    private async handleError<R extends T = T>(resp: Response): Promise<R> {
        const json = await resp.json();
        throw json.errorMessage;
    }
}
