import { combineReducers } from 'redux';
// import { PersistPartial } from 'redux-persist/es/persistReducer';

import optionsReducer, { OptionsState } from './reducers/optionsReducer';
import mediaReducer, { MediaState } from './reducers/mediaReducer';
import metadataReducer, { MetadataState } from './reducers/metadataReducer';
import authReducer, { AuthState } from './reducers/authReducer';
import playerReducer, { PlayerState } from './reducers/playerReducer';
import { ASVPActions } from './actions';

//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export interface State {
    auth: AuthState;
    media: MediaState;
    metadata: MetadataState;
    options: OptionsState;
    player: PlayerState;
}

//
// ─── REDUCERS ───────────────────────────────────────────────────────────────────
//

export default combineReducers<State, ASVPActions>({
    auth: authReducer,
    media: mediaReducer,
    metadata: metadataReducer,
    options: optionsReducer,
    player: playerReducer,
});
