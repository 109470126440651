import { Action } from 'redux';

import { AuthAction } from './reducers/authReducer';
import { MetadataAction } from './reducers/metadataReducer';
import { MediaAction } from './reducers/mediaReducer';
import { OptionsAction } from './reducers/optionsReducer';
import { PlayerAction } from './reducers/playerReducer';

//
// ─── ACTION TYPES ───────────────────────────────────────────────────────────────
//

export const READY = 'ASVP__READY';
export const DESTROY = '@@DESTROY';
export const EPIC_END = '@@EPIC_END';

export type ReadyAction = Action<typeof READY>;
export type DestroyAction = Action<typeof DESTROY>;
export type EpicEndAction = Action<typeof EPIC_END>;
export type LifecycleAction = ReadyAction | DestroyAction | EpicEndAction;

export type ASVPActions =
    | LifecycleAction
    | MetadataAction
    | MediaAction
    | OptionsAction
    | AuthAction
    | PlayerAction;

//
// ─── ACTION CREATORS ────────────────────────────────────────────────────────────
//

export const ready = (): ReadyAction => ({ type: READY });
export const destroy = (): DestroyAction => ({ type: DESTROY });
export const epicEnd = (): EpicEndAction => ({ type: EPIC_END });
