import { Reducer, combineReducers, Action } from 'redux';
import get from 'lodash/get';
import { ASVPOptions } from '../../ASVP';


//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export type UseAuthLib = boolean;


export interface OptionsState {
    useAuthLib: UseAuthLib;
}


//
// ─── ACTION TYPES ───────────────────────────────────────────────────────────────
//

export const SET_OPTIONS = 'OPTIONS__SET_OPTIONS';

export interface SetOptionsAction extends Action<typeof SET_OPTIONS> {
    payload: ASVPOptions;
}

export type OptionsAction = SetOptionsAction;


//
// ─── ACTION CREATORS ────────────────────────────────────────────────────────────
//

export const setOptions = (options: SetOptionsAction['payload']): SetOptionsAction => ({
    type: SET_OPTIONS,
    payload: options,
})


//
// ─── REDUCERS ───────────────────────────────────────────────────────────────────
//


export const useAuthLibReducer: Reducer<UseAuthLib, SetOptionsAction> = (state = true, action) => {
    switch (action.type) {
        case SET_OPTIONS:
            return get(action.payload, 'useAuthLib', true);
        default:
            return state;
    }
};


export default combineReducers<OptionsState>({
    useAuthLib: useAuthLibReducer
});
