import { map, switchMap, catchError, filter } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { combineEpics, Epic } from 'redux-observable';

import { State } from '../rootReducer';
import { setVideoData, setError } from '../reducers/metadataReducer';
import { ASVPActions } from '../actions';
import { serialize } from '../../utils/strings';

const params = {
    fields:
        'title,type,duration,collection_title,poster,stream,title_id,auth,media_id,launch_date,auth_launch_date,disable_ads,season_number,episode_number',
};

import VideoService from '../../api/VideoService';
import { ApiProvider } from '../../api';
import { select } from '../../utils/operators';

//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export interface MetadataEpicDependencies {
    videoData?: ApiProvider;
}

type MetadataEpic = Epic<ASVPActions, ASVPActions, State, MetadataEpicDependencies>;

//
// ─── EPIC ───────────────────────────────────────────────────────────────────────
//

const fetchVideoData: MetadataEpic = (action$, state$, { videoData = new VideoService() }) =>
    state$.pipe(
        select(state => state.metadata.videoID),
        filter(Boolean),
        switchMap(videoID =>
            from(videoData.request(`/${videoID}?${serialize(params)}`)).pipe(
                map((json: any) => setVideoData(json.data)),
                catchError(err => of(setError(err)))
            )
        )
    );

export default combineEpics(fetchVideoData);
