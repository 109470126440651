import queryString from 'query-string';
import { Player, PlayerConfigBuilder, PlayerConfigUIData, UISlate } from '@top/player-block-web';

export interface TopPlayerOptions {
    ads?: ASVPPlayerConfigAdData;
    container: HTMLDivElement;
    controlsEnabled?: boolean;
    mutedAutoplay?: boolean;
    debug?: ASVPPlayerConfigDebugData;
    media?: ASVPPlayerConfigMediaData;
    metadata?: ASVPPlayerConfigMetadata;
    ui?: PlayerConfigUIData;
}

export interface ASVPPlayerConfigAdData {
    enabled: boolean;
    kvps?: Record<string, string>;
    profile?: string;
}

export interface ASVPPlayerConfigMediaData {
    capBitrateToSize?: boolean;
    minBitrate?: number | null;
    maxBitrate?: number | null;
    offset?: number | null;
    startBitrate?: number | null;
}

export interface ASVPPlayerConfigDebugData {
    enabled: boolean;
}

export interface ASVPPlayerConfigMetadata {
    env?: string;
    companyId?: string;
    appId?: string;
    adType?: string;
}


const TopPlayer = (options: TopPlayerOptions) => {
    const player = new Player();

    // get time param from query and use it as default offset (if offset is passed into player init options will override it)
    const queryParams = queryString.parse(location.search);
    let queryOffset = (!!queryParams.t && typeof queryParams.t === 'string' ) ? parseFloat(queryParams.t) : 0;

    if (options.controlsEnabled === false) {
        // Doesn't render any controlBar UI elements
        options.ui = Object.assign({}, options.ui, {
            enabled: true,
            components: {
                active: [
                    UISlate.Media_Playback // Captions rendered here
                ]
            }
        })
    }

    const setupConfig = PlayerConfigBuilder.forSetup()
        .withContainer(options.container)
        .withMetadata({
            ...Object.assign({}, {
                env: window.AS.Environment.getEnv() === 'production' ? 'prod' : 'ite',
                companyId: 'e1b9076f-e876-436d-ba0a-d0fac24ff03e',
                appId: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuZXR3b3JrIjoiYWR1bHRzd2ltIiwicHJvZHVjdCI6InR2ZSIsInBsYXRmb3JtIjoid2ViLXRvcDIiLCJhcHBJZCI6ImFkdWx0c3dpbS10dmUtd2ViLXRvcDItZWphdWdvIn0.fjf3vyXbgvxlOQo8RBmic9FQtIUvE_oxVunWFpOiLwQ',
                adType: 'SSAI'
            }, options.metadata)
        })
        .withMedia({
            ...Object.assign({}, {
                offset: queryOffset,
                capBitrateToSize: true,
                minBitrate: null,
                maxBitrate: null,
                startBitrate: null
            }, options.media)
        })
        .withDebug({
            ...Object.assign({}, {
                enabled: false
            }, options.debug)
        })
        .withUI({
            ...Object.assign({}, {
                enabled: true
            }, options.ui)
        })
        .withAds({
            ...Object.assign({}, {
                profile: 'eaMMDsbsd0',
                enabled: true,
                kvps: {}
            }, options.ads)
        })
        .withPlayback({
            muted: (options.mutedAutoplay === true) ? true : false,
            autoPlayOptions: {
                forceMedia: (options.mutedAutoplay === true) ? true : false // turns on forced muted auto starting of content
            }
        })
        .build();

    player.setup(setupConfig);

    return player;
}


export default TopPlayer;
