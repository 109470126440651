import { Reducer, combineReducers, Action } from 'redux';
import { IPlayerError, PlayerErrorResult } from '@top/player-block-web';


//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//


export interface PlayerState {
    error: IPlayerError | null;
}

//
// ─── ACTION TYPES ───────────────────────────────────────────────────────────────
//

export const SET_PLAYER_ERROR = 'PLAYER__SET_ERROR';

export interface SetErrorAction extends Action<typeof SET_PLAYER_ERROR> {
    payload: PlayerErrorResult;
}

export type PlayerAction = SetErrorAction;

//
// ─── ACTION CREATORS ────────────────────────────────────────────────────────────
//


export const setPlayerError = (error: PlayerErrorResult): SetErrorAction => ({
    type: SET_PLAYER_ERROR,
    payload: error,
})



//
// ─── REDUCERS ───────────────────────────────────────────────────────────────────
//

export const errorReducer: Reducer<IPlayerError | null, PlayerAction> = (state = null, action) => {
    switch (action.type) {
        case SET_PLAYER_ERROR:
            return action.payload.error;
        default:
            return state;
    }
};


export default combineReducers<PlayerState>({
    error: errorReducer
});
