import { map, tap, ignoreElements, filter, switchMap, withLatestFrom, take, distinctUntilChanged } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { Observable, zip } from 'rxjs';
import format from 'date-fns/format';

import { State } from '../rootReducer';
import { ASVPActions } from '../actions';
import { ApiProvider } from '../../api';
import { select } from '../../utils/operators';
import { setPlayerError } from '../reducers/playerReducer';
import { ContentEntryBuilder, Player, PlayerErrorResult, PlayerConfigBuilder, ComscoreConfig } from '@top/player-block-web';
import { MetadataState } from '../reducers/metadataReducer';
import { APIVideoType, getComscoreMediaType } from '../../tracking';


//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export interface PlayerEventsEpicDependencies {
    auth: any;
    player: Player;
    videoData?: ApiProvider;
}

type PlayerEventsEpic = Epic<ASVPActions, ASVPActions, State, PlayerEventsEpicDependencies>;

//
// ─── EPIC ───────────────────────────────────────────────────────────────────────
//

// const playerEventsEpic: PlayerEventsEpic = (action$, state$, { player, videoData = new VideoService(), auth }) => {
//     console.log(player, state$, auth);

//     return merge(
//         fromEventPattern((h) => player.events.mediaTimeChanged.listen(h)).pipe(map((r) => ({type: 'ACTION!!', payload: 'fuck'}))),
//         fromEventPattern((h) => player.events.playerReady.listen(h)).pipe(
//             map(() => {}),
//             ignoreElements()
//         ),
//         fromEventPattern((h) => player.events.mediaLoaded.listen(h)).pipe(mapTo(mediaLoaded(player.model))),
//         fromEventPattern((h) => player.events.mediaReady.listen(h)).pipe(
//             switchMap(() => videoData.request<VideoDataResponse>('/r0xw2u30R3GchW27yVqlug?' + serialize(params)).pipe(
//                 map(json => {
//                     console.log(json.data)
//                     return { type: 'Loaded!', payload: json.data }
//                 }),
//                 catchError((err) => of({ type: 'ERROR', payload: err }))
//         ))),
//     )}

function buildComscoreConfig(metadata: MetadataState, player: Player): ComscoreConfig {

    const lastAirDate = metadata.launchDate
                        ? format(new Date(metadata.launchDate * 1000), 'YYYY-MM-DD HH:mm:ss')
                        : undefined;
    
    return {
        enabled: true,
        publisherId: "6035748",
        applicationName: "TOP",
        applicationVersion: player.config.metadata.playerVersion ?? "",
        isOTT: false,
        debug: false,
        childDirectedAppMode: false,
        mediaType: getComscoreMediaType(metadata.videoType),
        uniqueContentId: metadata.mediaID,
        publisherBrandName: "Adult Swim",
        programTitle: metadata.collectionTitle,
        programId: metadata.videoID,
        episodeTitle: metadata.title,
        episodeId: metadata.titleID,
        episodeSeasonNumber: metadata.seasonNumber?.toString(),
        episodeNumber: metadata.episodeNumber?.toString(),
        contentGenre: "*null",
        advertisementLoad: true,
        digitalAirdate: lastAirDate,
        tvAirdate: lastAirDate,
        stationTitle: "Adult Swim",
        c3: "*null",
        c4: "ADULTSWIM",
        c6: "*null",
        completeEpisode: metadata.videoType === APIVideoType.EPISODE,
        feedType: metadata.videoType
    }
}

// when auth token and media id have been updated, use it to play the media
const playbackAuthWithMediaID: PlayerEventsEpic = (action$, state$, { player }) => {
    return zip( // get token and mediaID
        state$.pipe(select(state => state.auth.token)),
        state$.pipe(select(state => state.metadata.mediaID))
    ).pipe(
        filter(([token, mediaID]) => !!token && !!mediaID), // if token and mediaID are truthy
        switchMap(([token, mediaID]) => state$.pipe( // switch to getting the poster image
            withLatestFrom(
                state$.pipe(map(state => state.metadata)),
            ),
            take(1), // only do this once per token/mediaID combo
            tap(([, metadata]) => { // play content via mediaID with token
                const playConfig = PlayerConfigBuilder.forPlay()
                    .withUI({ posterImage: metadata.poster })
                    .withComscore(buildComscoreConfig(metadata, player))
                    .build()

                player.updateConfig(playConfig)

                const playOptions = ContentEntryBuilder.forEntryOptions()
                    .withAccessToken(token)
                    .withAccessTokenType('adobe')
                    .withPlayConfigOverrides(playConfig)
                    .build();
                    
                player.playByMediaJson({ mediaId: mediaID }, playOptions);
            }),
            ignoreElements()
        ))
    );
};

const playbackUnauthWithMediaID: PlayerEventsEpic = (action$, state$, { player }) => {
    return state$.pipe(
        select(state => state.metadata.mediaID),
        filter(mediaID => !!mediaID), // if mediaID is truthy
        distinctUntilChanged(),
        switchMap(mediaID => state$.pipe( // switch to getting the poster image
            withLatestFrom(
                state$.pipe(map(state => state.metadata)),
            ),
            take(1),
            filter(([, metadata]) => !metadata.isAuth),
            tap(([, metadata]) => { // play content via mediaID with token
                // console.log('playbackUnauthWithMediaID', metadata)

                const playConfig = PlayerConfigBuilder.forPlay()
                    .withUI({ posterImage: metadata.poster })
                    .withComscore(buildComscoreConfig(metadata, player))
                    .build()

                player.updateConfig(playConfig)

                const playOptions = ContentEntryBuilder.forEntryOptions()
                        .withPlayConfigOverrides(playConfig)
                        .build();

                player.playByMediaJson({ mediaId: mediaID }, playOptions);
            }),
            ignoreElements()
        ))
    );
};

// when playback url is set, use it to play back the media
const playbackWithoutMediaID: PlayerEventsEpic = (action$, state$, { player }) =>
    state$.pipe(
        select(state => state.metadata.playbackURL), // get playbackURL
        filter((playbackURL) => !!playbackURL), // if truthy
        switchMap((playbackURL) => state$.pipe( // switch to getting the poster image
            withLatestFrom(
                state$.pipe(map(state => state.metadata)),
            ),
            take(1), // only do this once per token/mediaID combo
            tap(([, metadata]) => { // play content via mediaID with token
                // console.log('playbackWithoutMediaID', playbackURL)

                const playConfig = PlayerConfigBuilder.forPlay()
                    .withUI({ posterImage: metadata.poster })
                    .withAds({ enabled: false })
                    .withComscore(buildComscoreConfig(metadata, player))
                    .build()
                const playOptions = ContentEntryBuilder.forEntryOptions()
                    .withPlayConfigOverrides(playConfig)
                    .build();
                player.play(playbackURL,playOptions);
            }),
            ignoreElements()
        ))
    );


const onPlayerError: PlayerEventsEpic = (action$, state$, { player }) => {
    const playerModel$ = new Observable<PlayerErrorResult>(observer => {
        const off = player.events.playerError.listen((error: PlayerErrorResult) => {
            console.error("playerError", error);
            observer.next(error);
        });
        return () => {
            player.events.playerError.unlisten(off);
        };
    });

    return playerModel$.pipe(map(setPlayerError));
}


const Epics = combineEpics(
    // playerEventsEpic,
    playbackAuthWithMediaID,
    playbackUnauthWithMediaID,
    playbackWithoutMediaID,
    onPlayerError,
);

export default Epics;
