export function getBaseURL() {
    if (/(\w*.)adultswim.com$/.test(window.location.hostname)) {
        return '';
    } else {
        try {
            return AS.Environment.getBaseURL(true).replace(/\/+$/, '');
        } catch (error) {
            return '';
        }
    }
}
