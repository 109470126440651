import { ComScoreMediaType } from '@top/player-block-web';

export enum APIVideoType {
    CLIP = 'clip',
    EPISODE = 'episode',
    MARATHON = 'marathon',
    STREAM = 'stream',
};

export enum ADBPVideoType {
    CLIP = 'CLI',
    EPISODE = 'EPI',
    MARATHON = 'STR',
    STREAM = 'STR',
};

export enum AnalyticsEvents {
    VideoPreroll = 'video-preroll',
    VideoStart = 'video-start',
    VideoProgress = 'video-progress',
    VideoComplete = 'video-complete',
    VideoPause = 'video-pause'
}

// Convert between videoType string returned by the video API and what is desired for analytics
export function getVideoType(type: string) {
    switch (type) {
        case APIVideoType.CLIP:
            return ADBPVideoType.CLIP;
        case APIVideoType.EPISODE:
            return ADBPVideoType.EPISODE;
        case APIVideoType.MARATHON:
            return ADBPVideoType.MARATHON;
        case APIVideoType.STREAM:
            return ADBPVideoType.STREAM;
        default:
            return ADBPVideoType.CLIP;
    }
}

export function getComscoreMediaType(type: string){
    switch (type) {
        case APIVideoType.CLIP:
            return ComScoreMediaType.ShortFormOnDemand;
        case APIVideoType.EPISODE:
            return ComScoreMediaType.LongFormOnDemand;
        case APIVideoType.MARATHON:
        case APIVideoType.STREAM:
            return ComScoreMediaType.Live;
        default:
            return ComScoreMediaType.Other;
    }
}

export function inIframe (checkDomain = false) {
    try {
        if (checkDomain) return !document.referrer.includes('adultswim.com') && (window.self !== window.top);
        else return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function getCombinedVideoTitle (videoType: string, videoAuth: boolean, videoTitle: string, videoCollectionTitle: string) {
    return (videoType === APIVideoType.STREAM && !videoAuth) ? 'streams:' + videoTitle : videoCollectionTitle + ':' + videoTitle;
}
