import { getBaseURL } from '../utils/site';

import Fetcher from './Fetcher';

import { DataResponse, ApiProvider } from '.';

//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export interface VideoDataRoot {
    video: VideoData;
    collection: CollectionData;
}

export interface CollectionData {
    title_id: string;
    id: string;
    title: string;
    poster: string;
    slug: string;
    url: string;
}

export interface VideoData {
    auth: boolean;
    auth_launch_date: number | null;
    collection_title: string;
    disable_ads: boolean;
    duration: string;
    episode_number: string;
    id: string;
    launch_date: number | null;
    media_id: string;
    poster: string;
    season_number: string;
    stream: StreamData;
    title: string;
    title_id: string;
    type: string;
    url: string;
}

export interface StreamData {
    assets: AssetData[];
    duration: number;
    type: string;
}

export interface AssetData {
    closed_captions: false
    created: number;
    duration: string;
    id: string;
    mime_type: string;
    modified: number;
    title: string;
    url: string;
    version: number;
}

export type VideoDataResponse = DataResponse<VideoDataRoot>;


//
// ─── SERVICE ────────────────────────────────────────────────────────────────────
//

export default class VideoService extends Fetcher implements ApiProvider {
    constructor(baseURL = getBaseURL() + '/api/shows/v1/videos') {
        super(baseURL);
    }
}
