
//
// ─── MEDIA REDUCER ────────────────────────────────────────────────────────────────
// State Management for the Media Content being played based on TOP media data object
// Such - playlist url, volume, duration, captions, isLive, etc


import { Reducer, combineReducers, Action } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import get from 'lodash/get';
import { IPlayerModel } from '@top/player-block-web';


//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export type PlaylistURL = string;

export interface MediaState {
    playlistURL: PlaylistURL;
    muted: boolean;
}

//
// ─── ACTION TYPES ───────────────────────────────────────────────────────────────
//

export const MEDIA_LOADED = 'MEDIA__LOADED';
export const MEDIA_MUTED = 'MEDIA__MUTED';

export interface MediaLoadedAction extends Action<typeof MEDIA_LOADED> {
    payload: IPlayerModel;
}

export interface MediaMutedAction extends Action<typeof MEDIA_MUTED> {
    payload: boolean;
}


export type MediaAction = MediaLoadedAction | MediaMutedAction;

//
// ─── ACTION CREATORS ────────────────────────────────────────────────────────────
//

export const mediaLoaded = (iPlayerModel: MediaLoadedAction['payload']): MediaLoadedAction => ({
    type: MEDIA_LOADED,
    payload: iPlayerModel,
})


export const mediaMuted = (muted: MediaMutedAction['payload']): MediaMutedAction => ({
    type: MEDIA_MUTED,
    payload: muted,
})



//
// ─── REDUCERS ───────────────────────────────────────────────────────────────────
//

export const playlistURLReducer: Reducer<PlaylistURL, MediaAction> = (state = '', action) => {
    switch (action.type) {
        case MEDIA_LOADED:
            return get(action.payload, 'playlistItem.entry.files[0].url', '');
        default:
            return state;
    }
};

export const mutedReducer: Reducer<boolean, MediaAction> = (state = false, action) => {
    switch (action.type) {
        case MEDIA_MUTED:
            return action.payload;
        default:
            return state;
    }
};



const mediaReducer = combineReducers<MediaState>({
    playlistURL: playlistURLReducer,
    muted: mutedReducer,
    // currentTime
    // duration
    // isLive
    // volume
    // levelURL

    // muted ?
    // started
    // hasCaptions
});

const persistedReducer: Reducer = persistReducer({
    key: 'asvp:media',
    storage,
    whitelist: ['muted'],
}, mediaReducer)

export default persistedReducer;
