import identity from 'lodash/identity';
import { OperatorFunction } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

export const select = <T, R>(
    project: (value: T, index: number) => R = identity,
    compare?: (x: R, y: R) => boolean
): OperatorFunction<T, R> => source =>
    source.pipe(
        map(project),
        distinctUntilChanged(compare)
    );
