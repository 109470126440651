import { map, switchMap, withLatestFrom, filter, tap, ignoreElements } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Epic, combineEpics, ofType } from 'redux-observable';

import { State } from '../rootReducer';
import { mediaMuted, mediaLoaded } from '../reducers/mediaReducer';
import { ASVPActions } from '../actions';
import { AUTH_READY } from '../reducers/authReducer';
import { Player, IPlayerModel } from '@top/player-block-web';

//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export interface MediaEpicDependencies {
    player: Player;
}

type MediaEpic = Epic<ASVPActions, ASVPActions, State, MediaEpicDependencies>;

//
// ─── EPIC ───────────────────────────────────────────────────────────────────────
//

// listen for mute state in order to save it to the store
export const mutedStateEpic: MediaEpic = (_, __, { player }) => {
    const muted$ = new Observable<boolean>(observer => {
        const off = player.events.muteChanged.listen((event: any) => {
            if (event && typeof event.muted === 'boolean') {
                observer.next(event.muted);
            }
        });
        return () => {
            player.events.muteChanged.unlisten(off);
        };
    });

    return muted$.pipe(map(mediaMuted));
};

// listen for media loaded in order to get information about the media
export const mediaLoadedEpic: MediaEpic = (_, __, { player }) => {
    const iPlayerModel$ = new Observable<IPlayerModel>(observer => {
        const off = player.events.mediaLoaded.listen(() => {
            observer.next(player.model);
        });
        return () => {
            player.events.mediaLoaded.unlisten(off);
        };
    });

    return iPlayerModel$.pipe(map(mediaLoaded));
};


// set initial mute state on player ready based off the muted value that was persisted from local storage
export const initialMuteStateEpic: MediaEpic = (action$, state$, { player }) => {
    return action$.pipe(
        ofType(AUTH_READY),
        switchMap(() => state$.pipe(
            withLatestFrom(
                state$.pipe(map(state => state.media.muted)),
            ),
            map(([, muted]) => muted),
            filter(Boolean),
            tap(() => {
                player.mute()
            }),
            ignoreElements()
        ))
    )
};


const Epics = combineEpics(
    initialMuteStateEpic,
    mediaLoadedEpic,
    mutedStateEpic,
);

export default Epics;
