import { map, switchMap, catchError, tap, ignoreElements, withLatestFrom, filter } from 'rxjs/operators';
import { of, from, fromEventPattern } from 'rxjs';
import { combineEpics, Epic, ofType } from 'redux-observable';

import { setToken, authError, AUTH_READY, AUTH_LOGIN, authLogin, authLogout, AuthError } from '../reducers/authReducer';
import { State } from '../rootReducer';
import { ASVPActions } from '../actions';
import { select } from '../../utils/operators';
import { Player } from '@top/player-block-web';
import { SET_VIDEO_DATA } from '../reducers/metadataReducer';

let errorDiv: HTMLDivElement;

//
// ─── TYPINGS ────────────────────────────────────────────────────────────────────
//

export interface AuthEpicDependencies {
    auth: any;
    player: Player;
}

type AuthEpic = Epic<ASVPActions, ASVPActions, State, AuthEpicDependencies>;

// get and sets the auth token used for protected playback WHEN
// auth lib is ready OR LOGIN action is fired or video data is set with new content
const setTokenEpic: AuthEpic = (action$, state$, { auth }) => {
    return action$.pipe(
        ofType(AUTH_READY, AUTH_LOGIN, SET_VIDEO_DATA),
        switchMap(() =>  state$.pipe(
            select(state => state.options.useAuthLib),
            filter(useAuthLib => !!useAuthLib),
            switchMap(() => from(auth.authorize()).pipe(
                map((result: any) => setToken(result.token)),
                catchError((err: AuthError) => of(authError(err))),
            ))
        ))
    )
}

// when there's an auth error, display that message
const onAuthErrorEpic: AuthEpic = (__, state$, { player }) => state$.pipe(
    withLatestFrom(
        state$.pipe(map(state => state.auth.error)),
    ),
    map(([, error]) => error),
    filter(Boolean),
    tap((error) => {
        if (!errorDiv) {
            errorDiv = document.createElement('div');
            errorDiv.className = 'error-message';
        }
        player.model.rootContainer.appendChild(errorDiv);
        errorDiv.innerHTML = error.message;
    }),
    ignoreElements()
)

const clearAuthErrorEpic: AuthEpic = (__, state$, { }) => state$.pipe(
    withLatestFrom(
        state$.pipe(map(state => state.auth.error)),
    ),
    map(([, error]) => error),
    filter((error) => !error),
    tap(() => {
        if (errorDiv && errorDiv.parentNode) {
            errorDiv.parentNode.removeChild(errorDiv);
        }
    }),
    ignoreElements()
)

const onLoginEpic: AuthEpic = (action$, state$, { auth }) => action$.pipe(
    ofType(AUTH_READY),
    switchMap(() => state$.pipe(
        select(state => state.options.useAuthLib),
        filter(useAuthLib => !!useAuthLib),
        switchMap(() => fromEventPattern((h) => auth.events.loginSuccess.listen(h)).pipe(
            map((result: any) => (authLogin(result?.context)))
        ))
    ))
)

const onLogoutEpic: AuthEpic = (action$, state$, { auth }) => action$.pipe(
    ofType(AUTH_READY),
    switchMap(() => state$.pipe(
        select(state => state.options.useAuthLib),
        filter(useAuthLib => !!useAuthLib),
        switchMap(() => fromEventPattern((h) => auth.events.logoutSuccess.listen(h)).pipe(
            map(() => authLogout())
        ))
    ))
)

const Epics = combineEpics(
    setTokenEpic,
    onAuthErrorEpic,
    clearAuthErrorEpic,
    onLoginEpic,
    onLogoutEpic,
);

export default Epics;
