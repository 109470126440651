import { createStore, Store as ReduxStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware, ofType } from 'redux-observable';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { persistStore } from 'redux-persist';

import rootReducer, { State } from './rootReducer';
import rootEpic, { EpicDependencies } from './rootEpic';
import { DESTROY, EPIC_END, epicEnd, ASVPActions } from './actions';

export type Store = ReduxStore<State>;

export const configureStore = (deps: EpicDependencies) => {
    const epicMiddleware = createEpicMiddleware<ASVPActions, ASVPActions, State, EpicDependencies>({
        dependencies: deps,
    });

    const store = createStore<State, ASVPActions, {}, {}>(
        rootReducer,
        composeWithDevTools(applyMiddleware(epicMiddleware))
    );

    const persistor = persistStore(store);

    // Every time a new epic is given to rootEpic$ it
    // will unsubscribe from the previous one then
    // call and subscribe to the new one
    const rootEpic$ = new BehaviorSubject(rootEpic);

    // wrap the root epic and pipe a takeUntil that unsubscribe from all epics
    // on a DESTROY action
    const wrappedEpic: typeof rootEpic = (action$, ...args) =>
        rootEpic$.pipe(
            mergeMap(epic =>
                epic(action$, ...args).pipe(takeUntil(action$.pipe(ofType(DESTROY, EPIC_END))))
            )
        );

    // If HMR is enabled, setup hot reloading to replace the root epic when changes are made
    // otherwise, just run the root epic and be done with it
    if (module.hot) {
        module.hot.accept('./rootEpic', () => {
            const nextRootEpic = require('./rootEpic').default;
            console.log('[HMR] 🔄 Replacing root epic...');
            // kill any running epics
            store.dispatch(epicEnd());
            rootEpic$.next(nextRootEpic);
            console.log('[HMR] ✅ Replaced root epic.');
        });

        module.hot.accept('./rootReducer', () => {
            const nextReducer = require('./rootReducer').default;
            store.replaceReducer(nextReducer);
        });
    }

    if (process.env.NODE_ENV !== 'test') {
        epicMiddleware.run(wrappedEpic);
    }

    return {
        persistor,
        store,
    };
};
